import React from "react";

import cn from "classnames";

import * as style from "./Loader.module.scss";

interface Props {
  size?: "small" | "medium" | "large";
  className?: string;
}

export const Loader: React.FC<Props> = ({ size = "medium", className }) => (
  <div className={cn(className, style.loader, style[size])} />
);
