import React from "react";

import { Container } from "src/components/Container";
import { Typography } from "src/components/Typography";
import {
  Speaker,
  SpeakerProps,
} from "src/layouts/Event/blocks/SpeakersBlock/Speaker";

import * as styles from "./SpeakersBlock.module.scss";

interface SpeakerBlockProps {
  speakers: SpeakerProps[];
}

export const SpeakersBlock: React.FC<SpeakerBlockProps> = ({ speakers }) => {
  return (
    <Container>
      <Typography variant="h3" animate>
        Спикеры
      </Typography>
      <div className={styles.wrapper}>
        {speakers.map(({ name, avatar, description }) => (
          <Speaker
            key={name}
            avatar={avatar}
            description={description}
            name={name}
          />
        ))}
      </div>
    </Container>
  );
};
