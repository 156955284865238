import React from "react";

import { Container } from "src/components/Container";
import { Typography } from "src/components/Typography";
import {
  ProgramItem,
  ProgramItemProps,
} from "src/layouts/Event/blocks/ProgramBlock/ProgramItem";

import * as styles from "./ProgramBlock.module.scss";

interface ProgramBlockProps {
  agenda: ProgramItemProps[];
}

export const ProgramBlock: React.FC<ProgramBlockProps> = ({ agenda }) => {
  return (
    <div className={styles.back}>
      <Container rootClassName={styles.root}>
        <Typography variant="h3" animate color="white">
          Программа
        </Typography>
        <div className={styles.wrap}>
          {agenda.map(({ id, time, speaker, title }) => (
            <ProgramItem key={id} time={time} speaker={speaker} title={title} />
          ))}
        </div>
      </Container>
    </div>
  );
};
