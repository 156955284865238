import React from "react";

import { Banner } from "src/components/Banner";
import { Button } from "src/components/Button";
import { Container } from "src/components/Container";
import { Link } from "src/components/Link";
import { LinkList } from "src/components/LinkList";
import { PageWrapper } from "src/components/PageWrapper";
import { Typography } from "src/components/Typography";
import { PathEnum } from "src/enums/PathEnum";
import { EventProps } from "src/layouts/Event/EventProps";
import { blue300 } from "src/styles/variables.module.scss";

import { ProgramBlock } from "./blocks/ProgramBlock";
import { SpeakersBlock } from "./blocks/SpeakersBlock";
import * as styles from "./Event.module.scss";

const Event: React.FC<EventProps> = ({
  address,
  agenda,
  dateTime,
  speakers,
  title,
  description,
  picture,
  event_categories,
  link,
}) => {
  const eventDate = new Date(dateTime);
  const eventCategories = event_categories.map(({ name, slug }) => ({
    text: name,
    href: `${PathEnum.Events}/${slug}`,
    active: false,
  }));

  return (
    <PageWrapper>
      <div>
        <Banner
          backgroundImage={picture.localFile.publicURL}
          backgroundColor={blue300}
          className={styles.bannerRoot}
        >
          <Container>
            <Typography variant="h3" color="white" className={styles.title}>
              {title}
            </Typography>

            <div className={styles.bannerFooter}>
              <LinkList
                items={eventCategories}
                flexWrap={true}
                linkProps={{ hoverFill: "white" }}
              />
              <Link
                showArrow
                arrowPosition="left"
                rounded={false}
                className={styles.showMoreLink}
                disableArrowHide
                href="../"
              >
                Все мероприятия
              </Link>
            </div>
          </Container>
        </Banner>
        <section className={styles.intro}>
          <div className={styles.leftColumn}>
            <div className={styles.meta}>
              <div className={styles.date}>
                <Typography color="white">
                  {eventDate.toLocaleDateString("ru", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </Typography>
                <Typography color="white">
                  {eventDate.getHours()}:
                  {String(eventDate.getMinutes()).padStart(2, "0")}{" "}
                </Typography>
              </div>
              <Typography className={styles.address} color="white">
                {address}
              </Typography>
            </div>
            <Button link={link} className={styles.button} color="purple" shadow>
              зарегистрироваться
            </Button>
          </div>
          <Container rootClassName={styles.rightColumn}>
            <Typography>{description}</Typography>
          </Container>
        </section>
        <SpeakersBlock speakers={speakers} />
        <ProgramBlock agenda={agenda} />
        <div className={styles.footerButtonContainer}>
          <Button link={link} className={styles.button} color="purple" shadow>
            зарегистрироваться
          </Button>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Event;
