import React, { ComponentPropsWithoutRef } from "react";

import cn from "classnames";
import { Loader } from "src/components/Loader";
import { Typography } from "src/components/Typography";

import * as styles from "./Button.module.scss";

interface Props extends ComponentPropsWithoutRef<"button"> {
  color?: "purple" | "green" | "gradient" | "violet";
  className?: string;
  variant?: "outlined" | "base" | "squared";
  shadow?: boolean;
  link?: string;
  loading?: boolean;
}

export const Button: React.FC<Props> = ({
  className,
  shadow,
  variant = "base",
  color = "green",
  children,
  type = "button",
  link,
  loading,
  ...other
}) => {
  const buttonChild = (
    <>
      {loading && <Loader />}
      <Typography variant="h5" className={styles.text}>
        {children}
      </Typography>
    </>
  );

  return link ? (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={cn(
        className,
        styles[variant],
        styles.root,
        styles[color],
        shadow && styles.shadow
      )}
    >
      {buttonChild}
    </a>
  ) : (
    <button
      type={type}
      {...other}
      className={cn(
        className,
        styles[variant],
        styles.root,
        styles[color],
        shadow && styles.shadow
      )}
    >
      {buttonChild}
    </button>
  );
};
