// extracted by mini-css-extract-plugin
export var mobile = "766px";
export var tablet = "1025px";
export var desktop = "1368px";
export var blue300 = "#0929ae";
export var violet300 = "#4f2c98";
export var blue200 = "#1b3bc0";
export var pink200 = "#ef5b72";
export var bannerRoot = "Event-module--bannerRoot--18DrV";
export var title = "Event-module--title--2UM7C";
export var bannerFooter = "Event-module--bannerFooter--3ZOBz";
export var showMoreLink = "Event-module--showMoreLink--3gBF7";
export var leftColumn = "Event-module--leftColumn--136Xv";
export var meta = "Event-module--meta--28gis";
export var rightColumn = "Event-module--rightColumn--sfG3X";
export var date = "Event-module--date--1ocBK";
export var address = "Event-module--address--1TW2j";
export var button = "Event-module--button--1RSrg";
export var intro = "Event-module--intro--3HU9w";
export var footerButtonContainer = "Event-module--footerButtonContainer--2YOm5";