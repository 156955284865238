// extracted by mini-css-extract-plugin
export var root = "Button-module--root--2w5CA";
export var shadow = "Button-module--shadow--14Ual";
export var base = "Button-module--base--rFboH";
export var outlined = "Button-module--outlined--37eka";
export var squared = "Button-module--squared--38gdT";
export var text = "Button-module--text--1ejO-";
export var purple = "Button-module--purple--3Ur1H";
export var green = "Button-module--green--36UD1";
export var gradient = "Button-module--gradient--2vWFc";
export var violet = "Button-module--violet--IX93b";