// extracted by mini-css-extract-plugin
export var mobile = "766px";
export var tablet = "1025px";
export var desktop = "1368px";
export var blue300 = "#0929ae";
export var violet300 = "#4f2c98";
export var blue200 = "#1b3bc0";
export var pink200 = "#ef5b72";
export var column = "Speaker-module--column--3wm90";
export var wrapImg = "Speaker-module--wrapImg--3PfqJ";
export var box = "Speaker-module--box--3teLv";
export var text = "Speaker-module--text--pT2zk";
export var name = "Speaker-module--name--1Ft5F";