import React from 'react'

import { graphql, PageProps } from "gatsby";
import Event from 'src/layouts/Event';
import { EventProps } from "src/layouts/Event/EventProps";

export interface StrapiEvents {
  strapiEvents: EventProps
}

const EventPage: React.FC = ({ data }: PageProps<StrapiEvents>) => {
  return (
      <Event {...data.strapiEvents} />
  )
}

export const query = graphql`
  query ($id: String) {
    strapiEvents(id: {eq: $id}) {
      title
      address
      agenda
      dateTime
      description
      link
      picture {
        localFile {
          publicURL
        }
      }
      speakers {
        description
        name
        avatar {
          localFile {
            publicURL
          }
        }
      }
      event_categories {
        name
        slug
      }
    }
  }
`

export default EventPage
