// extracted by mini-css-extract-plugin
export var mobile = "766px";
export var tablet = "1025px";
export var desktop = "1368px";
export var blue300 = "#0929ae";
export var violet300 = "#4f2c98";
export var blue200 = "#1b3bc0";
export var pink200 = "#ef5b72";
export var item = "ProgramItem-module--item--2xlD-";
export var time = "ProgramItem-module--time--25jX0";
export var name = "ProgramItem-module--name--1klWq";
export var author = "ProgramItem-module--author--361tU";