// extracted by mini-css-extract-plugin
export var mobile = "766px";
export var tablet = "1025px";
export var desktop = "1368px";
export var blue300 = "#0929ae";
export var violet300 = "#4f2c98";
export var blue200 = "#1b3bc0";
export var pink200 = "#ef5b72";
export var root = "ProgramBlock-module--root--1xF-L";
export var back = "ProgramBlock-module--back--3zXGP";
export var wrap = "ProgramBlock-module--wrap--2Jdo2";