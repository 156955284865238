import React from "react";

import { Typography } from "src/components/Typography";
import { SpeakerProps } from "src/layouts/Event/blocks/SpeakersBlock/Speaker";

import * as styles from "./ProgramItem.module.scss";

export interface ProgramItemProps {
  id?: string
  speaker?: SpeakerProps
  time: string
  title: string
}

export const ProgramItem: React.FC<ProgramItemProps> = ({speaker, time, title}) => {
  return (
      <div className={styles.item}>
        <Typography className={styles.time} variant='p' color='white'>{time.slice(0, 5)}</Typography>
        <Typography className={styles.name} variant='p' color='white'>{title}</Typography>
        <Typography className={styles.author} variant='p' color='white'>{speaker?.name}</Typography>
      </div>
  )
}
