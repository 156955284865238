import React from "react";

import { Typography } from "src/components/Typography";

import * as styles from "./Speaker.module.scss";

export interface SpeakerProps {
  avatar: {
    localFile: {
      publicURL: string
    }
  },
  description: string,
  name: string
}

export const Speaker: React.FC<SpeakerProps> = ({avatar, description, name}) => {
  return (
      <div className={styles.box}>
        <div className={styles.wrapImg}>
          <img alt="Фотография спикера" src={avatar.localFile.publicURL} />
        </div>
        <div className={styles.column}>
          <Typography className={styles.name} variant="h5" color="white">
            {name}
          </Typography>
          <Typography className={styles.text} color="white">
            {description}
          </Typography>
        </div>
      </div>
  )
}
